import "./App.css";
function SuccessStory(params) {
  return (
    <div className="quotes-bg">
      <p className="text-center">{params.story.quote}</p>
      <div className="role-bg">
        <h4 className="text-center">{params.story.name}</h4>
        <h6 className="text-center">{params.story.role}</h6>
      </div>
    </div>
  );
}
export default SuccessStory;
