import { useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "@mui/material/Button";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function Payment() {
  const [name, setUserName] = useState("");
  const [email, setEmailValue] = useState("");
  const [phone, setPhone] = useState("");
  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };
  const handleMailChange = (event) => {
    setEmailValue(event.target.value);
  };
  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      // key: "rzp_test_mNgkakUh48YruG", //karthi test key
      key: "rzp_live_Uovi357Gg7zD6M",
      amount: "99900",
      currency: "INR",
      name: "Revathi Asokan",
      description: "Test Transaction",
      // image: { logo },
      order_id: "",
      handler: async function (response) {
        console.log("ncnenev", response.razorpay_payment_id);
        if (
          response.razorpay_payment_id !== undefined &&
          response.razorpay_payment_id !== null
        ) {
          var request = {
            name: name,
            email: email,
            phone: phone,
          };
          await axios.post(
            "https://tth-website-api.onrender.com/send-acknowledge",
            JSON.stringify(request),
            { 
              headers: { 
                "Content-Type": "application/json",
              },
            }
          );
        }

      
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: "Soumya Dey Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.open();
  }
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      setUserName("");
      setEmailValue("");
      setPhone("");
      document.body.appendChild(script);
    });
  }
  return (
    <section>
      <Container>
        <div className="row py-3">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img
                src="https://assets.website-files.com/6302e597fbb6ee54fbee3b8a/630c8a92c693d29110158998_logocirclecolored.png"
                alt=""
                className="img-fluid"
                width={"60px"}
              ></img>
            </div>
            <h5>Business Startup Webinar</h5>
            <p>
              by <span style={{ color: "rgb(63 63 63 / 1" }}>Revathi</span>
            </p>

            <div style={{ display: "flex", flexDirection: "start" }}>
              <p style={{ textDecoration: "line-through" }}>₹2499</p>
              <h2 style={{ paddingLeft: "10px" }}>₹999</h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <img
                src="/assets/businesscard.png"
                className="img-fluid"
                alt=""
              ></img>
            </div>
            <div className="mt-4">
              <h5>Date: July 14th - 9:00 am to 12:00 pm IST</h5>
              <h5>A step by step guide to start your dream business</h5>
              <h5>Why attend this workshop:</h5>

              <li>
                Get Expert guidance and support from Revathi Asokan on how to
                start your Entrepreneurship journey
              </li>
              <li>Learn all the basic essentials for a startup</li>
              <li>Avoiding pitfalls, so you'll save both time and money.</li>
              <li>
                Learn how to select a right business that aligns with your
                passion while meeting market demand
              </li>
              <li>Learn how create a comprehensive business plan</li>
              <li>
                Unlock the must-have tech tools for efficient business
                operations
              </li>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 payDetails"
            style={{ backgroundColor: "rgb(245 247 249 / 1" }}
          >
            <h5>Payment Details</h5>
            <p>Complete your purchase by providing your payment details.</p>
            <div className="billing-details-bg">
              <div>
                <p style={{ color: "white" }}>
                  Why attend this webinar session?
                </p>
                <input
                  value={name}
                  className="name"
                  placeholder="Name"
                  onChange={handleNameChange}
                />

                <input
                  value={email}
                  placeholder="Email"
                  onChange={handleMailChange}
                />
                <br />
                <PhoneInput
                  style={{ marginTop: "15px" }}
                  country={"in"}
                  enableSearch={true}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
              </div>
              <div className="row justify-content-center align-items-center">
              <Button
                onClick={() => displayRazorpay()}
                variant="contained"
                className="col-lg-6 col-md-6 col-sm-12 payment-btn"
                style={{ marginTop: "3%" }}
              >
                Proceed to pay ₹999.00
              </Button>
            </div>
            </div>
        
            <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-4">
              <img
                src="https://learn.holistictherapistgayathri.com/_next/static/media/payment-sprite.82bf98c6.svg"
                className="img-fluid"
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
export default Payment;
