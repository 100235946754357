import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
var style = {
  backgroundColor: "white",
  textAlign: "center",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: "10px",
  paddingRight: "10px",
  position: "fixed",
  left: "0",
  bottom: "0",

  width: "100%",
  display: "flex",
  justifyContent: "space-around",
};

var phantom = {
  display: "block",
  padding: "20px",
  height: "100px",
  width: "100%",
};
var style1 = {
  display: "flex",
};
function Footer() {
  const navigate = useNavigate();
  const gotToNewPage = () => {
    navigate("/payment");
  };



  return (
    <div>
      <div style={phantom} />
      <div style={style} className="footer-bg">
        <div className="d-flex p-2 footertime">
        <div className="f-content">
          <h1
            style={{
              color: "#F00548",
              fontWeight: "700",
              fontFamily: "Montserrat, Sans-serif",
            }}
            className="mb-0"
          >
            ₹999
          </h1>
          <h3
            style={{
              color: "#F00548",
              fontWeight: "400",
              textDecoration: "line-through",
              opacity: "0.8",
              paddingLeft: "10px",
            }}
            className="mb-0"
          >
            ₹2,499
          </h3>
          {/* <h3 style={{ paddingLeft: "20px" }}>Offer Expires in</h3> */}
        </div>
        <div className="mx-2">
          <div
            className="date-flex"
          >
            <FaRegCalendarAlt color="#F00548" />
            <p className="mb-0 para-Foot" style={{ paddingLeft: "5px" }}>
              14th July
            </p> 
          </div>
          <div
            className="date-flex pt-1"
          >
            <FaRegClock color="#F00548"/>
            <p className="mb-0 para-Foot" style={{ paddingLeft: "1px" }}>
              9 AM - 12 PM IST
            </p>
          </div>
        </div> 
        </div>
        <div className="row justify-content-center align-items-center footertime">
          <div className="col-lg-6 col-md-6 col-sm-6 ">
            <p
              className="mb-0 reg-para"
              style={{ color: "black", fontWeight: "bold", fontSize: "14px" }}
            >
              This price is applicalabe for first <strong className="strong" style={{fontSize:"20px"}}>100</strong> registrations.
            </p>
          </div>
          <div className="col-lg-6 col-md-10 col-sm-6 count-flex">
            {" "}
            <Button
              variant="contained"
              onClick={() => gotToNewPage()}
              className="footer-btn"
              style={{ color: "white" }}
            >
              Join NOW AT <br />
              ₹999
            </Button>
            <span className="reg-left"><strong style={{fontSize:"20px"}}>{Math.floor(Math.random() * 60) + 1}</strong> Registrations left</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
