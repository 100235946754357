import "./App.css";
export function LeftOverView(params) {
  console.log("paramsss ", params.items.imgURL, params.items.content);
  return (
    <div className="mt-2 row justify-content-center align-items-center">
      <img
        src={`${params.items.imgURL}`}
        className="img-fluid col-lg-4 col-md-4 col-sm-12"
      ></img>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <h2>{params.items.title}</h2>
        <h5>{params.items.content}</h5>
      </div>
    </div>
  ); 
}

export function RightOverView(params) {
  console.log("paramsss ", params.items.imgURL, params.items.content);
  return (
    <div className="mt-2 row justify-content-center align-items-center">
      
      <div className="col-lg-6 col-md-6 col-sm-12 order-2 order-md-1 order-lg-1">
        <h2>{params.items.title}</h2> 
        <h5>{params.items.content}</h5>
      </div>
      <img
        src={`${params.items.imgURL}`}
        className="img-fluid col-lg-4 col-md-4 col-sm-12 order-sm-1 order-md-2 order-lg-2"
      ></img>
    </div>
  );
}

