import Accordion from "react-bootstrap/Accordion";

function FAQ(params) {
  return (
    <div>
      <div className="text-center mt-5">
        <h1>FAQ</h1>
      </div>
      <div className="faq-bg mb-4">
        <Accordion defaultActiveKey="0">
         
          <Accordion.Item eventKey="0">
            <Accordion.Header>When is the Webinar?</Accordion.Header>
            <Accordion.Body>
            The date and time of the webinar is mentioned on the top of the page.
            </Accordion.Body> 
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Will I get the Recording of the Program?</Accordion.Header>
            <Accordion.Body>
            This is a LIVE session and there will be no recordings.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>I made the payment but I didn't receive any mail</Accordion.Header>
            <Accordion.Body>
            Please check your inbox and spam folder for a mail from <span style={{fontWeight:"bold"}}>revathi.edupreneur@gmail.com.</span> If you have still not received, please send a mail to the same id and the team will get back with the link.
            </Accordion.Body>
          </Accordion.Item>
          
          <Accordion.Item eventKey="3">
            <Accordion.Header>How will I get the link to attend the program?</Accordion.Header>
            <Accordion.Body>
            You will receive an email 24 hours before the session. Please check your inbox and spam folder.
            </Accordion.Body> 
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>When will I get the Bonuses?</Accordion.Header>
            <Accordion.Body>
            You will get all the bonuses after you attend the Live session
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
export default FAQ;
