import { useState } from "react";
import { LeftOverView, RightOverView } from "./overview";
import axios from "axios";
import SuccessStory from "./success_story";
import FAQ from "./faq";
import Footer from "./footer";
import { FaStar } from "react-icons/fa";
import LearningTransparentBox from "./learning_trans";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import PhoneInput from "react-phone-input-2";
import { GoDotFill } from "react-icons/go";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";

import "react-phone-input-2/lib/bootstrap.css";

function Home() {
  const [name, setUserName] = useState("");
  const [email, setEmailValue] = useState("");
  const [phone, setPhone] = useState("");
  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };
  const handleMailChange = (event) => {
    setEmailValue(event.target.value);
  };
  const navigate = useNavigate();
  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    } 

    const options = {
      // key: "rzp_test_mNgkakUh48YruG", //karthi test key
      key: "rzp_live_Uovi357Gg7zD6M",
      amount: "99900",
      currency: "INR",
      name: "Revathi Asokan",
      description: "Test Transaction",
      // image: { logo },
      order_id: "",
      handler: async function (response) {
        console.log("ncnenev", response.razorpay_payment_id);
        if (
          response.razorpay_payment_id !== undefined &&
          response.razorpay_payment_id !== null
        ) {
          var request = {
            name: name,
            email: email,
            phone: phone,
          };
          await axios.post(
            "https://tth-website-api.onrender.com/send-acknowledge",
            JSON.stringify(request),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: "Soumya Dey Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      setUserName("");
      setEmailValue("");
      setPhone("");
      document.body.appendChild(script);
    });
  }
  var attendingReasons = [
    {
      text1: "Get ",
      text2: "Expert guidance and support ",
      text3:
        "from Revathi Asokan on how to start your Entrepreneurship journey",
    },
    {
      text1: "Learn all the ",
      text2: "basic essentials ",
      text3: "for a startup",
    },
    {
      text1: "Avoiding ",
      text2: "pitfalls, ",
      text3: "so you'll save both time and money.",
    },

    {
      text1: "Learn how to select a ",
      text2: "right business ",
      text3: "that aligns with your passion while meeting market demand",
    },
    { 
      text1: "Learn how to create a ",
      text2: "comprehensive business plan",
      text3: "",
    },
    {
      text1: "Unlock the must-have ",
      text2: "tech tools ",
      text3: "for efficient business operations.",
    },
    {
      text1:"A",
      text2: "step by step guide",
      text3: "to start your dream business",
    }
  ];
  var tips = [
    {
      text1: "You will learn how to define and refine a business idea to ensure it meets market needs.",
      text2: "",
      text3: "",
    },
    {
      text1: "Learn the fundamentals of conducting market research to identify target customers.",
      text2: "",
      text3: "",
    },
    {
      text1:
        "Basic business budgeting and financial planning techniques.",
      text2: "",
      text3: "",
    },
    {
      text1: "Foundational marketing concepts and strategies.",
      text2: "",
      text3: "",
    },
    {
      text1: "The techniques of sales and branding.",
      text2: "",
      text3: "",
    },
    {
      text1: "Right mindset to handle the business pressures.",
      text2: "",
      text3: "",

    },
    

  ];
  var lifeOutput = [
    {
      imgURL:
        "/assets/Rectangle 213.png",
      title: "Identifying the Right Business Idea",
      content:
        "Learn how to pinpoint a business idea that aligns with your interests, skills, and market demand.",
    },
    {
      imgURL:
        "/assets/Rectangle 214.png",
      title: "Navigating Various Business Models",
      content:
        "Gain insights into the pros and cons of different business models, including franchises, retail stores, e-commerce, and manufacturing units.",
    },
    {
      imgURL:
        "/assets/Rectangle 215.png",
      title: "Understanding Entrepreneurial Challenges",
      content:
        "Explore the common challenges faced by entrepreneurs and strategies to overcome them, based on real-world experiences.",
    },
    {
      imgURL:
        "/assets/Rectangle 216.png",
      title: "Minimising Risks",
      content:
        "Discover how to take calculated risks in entrepreneurship while minimizing potential pitfalls and maximising chances of success.",
    },
  ];
  var success_quotes = [
    {
      name: "Archana",
      role: "Software Engineer - Chennai",
      quote:
        "The session was a game-changer for me! As someone who's been hesitant to start my own business, Revathi's insights were invaluable. I finally feel equipped to choose the right business idea and take that leap of faith. Highly recommended!.It's amazing ",
    },
    {
      name: "KeerthiVasan",
      role: "Reseller - Bangalore",
      quote:
        "Having already run a small business, I thought I knew it all. But the webinar provided fresh perspectives and practical tips that I hadn't considered before. It's amazing how much you can learn from someone else's experiences. Don't miss out on this opportunity",
    },
    {
      name: "Sujatha Sugumaran",
      role: "House Wife - Coimbatore",
      quote:
        "I resigned my job after my baby and became a house wife. But i always wanted to do something from home but couldnt get any idea of what to do how to do. Thanks to Revathi who gave me a clear knowledge about how to change our passion into a business.",
    },
  ]; 

  var learningList = [
    {
      img: "https://holistictherapistgayathri.com/wp-content/uploads/2024/01/003-identify.png",
      subtitle:
        "Understand how to identify your passion and turn them into a successful business",
    },
    {
      img: "https://cdn-icons-png.flaticon.com/512/14313/14313944.png",
      subtitle:
        "The fundamentals of business starting from Purpose, Market Research, Value Proposition, Financial Management, Marketing and Branding",
    },
    {
      img: "https://holistictherapistgayathri.com/wp-content/uploads/2024/01/004-target.png",
      subtitle:
        "A step by step process to change your ideation into the actual business and pave way for long time success",
    },
    {
      img: "https://holistictherapistgayathri.com/wp-content/uploads/2024/01/006-brain.png",
      subtitle:
        "How to get the right mindset to become a successful entrepreneur.",
    },
    {
      img: "	https://cdn-icons-png.flaticon.com/128/7554/7554739.png",
      subtitle:
        "How to stop procrastinating the idea of your business and being stuck in the fire fighting",
    },
    {
      img: "https://holistictherapistgayathri.com/wp-content/uploads/2024/01/001-heart.png",
      subtitle:
        "Practical steps to increase the chances of success in your business by avoiding the mistake commonly people make.",
    },
  ];
  const gotToNewPage = () => {
    navigate("/payment");
  };
  return (
    <section>
      {/*Home Page */}
      <div className="home-bg">
        <Container>
          <div className="row justify-content-center">
            <div className="text-center col-lg-6 col-md-6 col-sm-12">
              <p
                style={{
                  color: "#F00548",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                Exclusive Business Startup Webinar! <br />
                {/* <span>3 HOURS ONLINE</span> */}
              </p>
            </div>
          </div>

          <div className="text-center title-bg">
            <div>
              <p className="mb-0">Embark On Your Entrepreneurial Journey</p>
            </div>
          </div>

          <div className="row py-3" style={{ paddingTop: "3rem" }}>
            <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="p-3">
                <div
                  className="p-3"
                  style={{ backgroundColor: "#04213F", borderRadius: "10px" }}
                >
                  <div className="journey-profile">
                    <div
                    className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "200px",
                        width: "200px",
                        overflow: "hidden",
                        borderRadius: "50%",
                        margin:"auto"
                      }}
                    >
                      <img 
                        src="/assets/Picture.jpg"
                        className="img-fluid"
                        alt=""
                        style={{borderRadius: "50%",}}
                      /> 
                    </div>
                    <div className="p-2" style={{ paddingLeft: "1rem",margin:"auto" }}>
                      <h1
                        style={{
                          color: "#F00548",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        Revathi Asokan
                      </h1>

                      <p
                      className="mb-0"
                        style={{
                          color: "white",
                          fontSize: "24px",
                          fontWeight: "500px",
                        }}
                      >
                        Business Mentor
                      </p>
                      <p
                        style={{
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        I will be your mentor for 3 hours
                      </p>
                      <div style={{ display: "flex" }}>
                        <FaStar color="#FFD600" paddingLeft="5px" />
                        <FaStar color="#FFD600" paddingLeft="5px" />
                        <FaStar color="#FFD600" paddingLeft="5px" />
                        <FaStar color="#FFD600" paddingLeft="5px" />
                        <FaStar color="#FFD600" paddingLeft="5px" />
                      </div>
                      <div className="pt-2">
          <div
            style={{
              display: "flex",
              height: "30px",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <FaRegCalendarAlt color="#F00548" />
            <p className="mb-0" style={{ paddingLeft: "5px",color:"#fff" }}>
              14th July
            </p> 
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <FaRegClock color="#F00548" />
            <p className="mb-0" style={{ paddingLeft: "5px",color:"#fff" }}>
              9 AM - 12 PM IST
            </p>
          </div>
        </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 px-0">
              <div className="">
                <div
                  className=""
                  style={{ backgroundColor: "#04213F", borderRadius: "10px" }}
                >
                  <h1
                    className="text-center pt-3"
                    style={{
                      color: "#F00548",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}                  >
                    Why attend this webinar session?
                  </h1>
                  <div>
                  <div style={{ marginTop: "2%" }}>
              {attendingReasons.map((item, index) => (
                <div key={index} className="col-lg-12 col-md-12 col-sm-1 attend-container">
                  <div className="row ">
                    <div className="col-sm-1 col-lg-1 col-md-1 col-1 p-0">
                      <GoDotFill color="#fff" size={"20px"} />
                    </div>
                    <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                      <p>
                        <span style={{ color: "#fff", fontSize: "16px" }}>
                          {item.text1}
                        </span>{" "}
                        <span
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {item.text2}
                        </span>{" "}
                        <span style={{ color: "#fff", fontSize: "16px" }}>
                          {item.text3}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
                  </div>
               
                
                </div>
              </div>
            </div>
          </div>
          
        </Container>
      </div>
      {/* By someone who has done it before........................................................... */}
      {/* <div style={{ textAlign: "center" }}>
        <h1
          className="text-stl center-txt-underline"
          style={{ fontWeight: "bold", fontSize: "28px" }}
        >
          By someone who has done it before
        </h1>
      </div> */}
      {/* <Container>
        {" "}
        <div style={{ marginTop: "3%" }}>
          <div>
            <div>
              <p
                style={{
                  color: "#F00548",
                  fontSize: "24px",
                  fontWeight: "bold",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Why attend this webinar
              </p>
            </div>
            <div style={{ marginTop: "2%" }}>
              {attendingReasons.map((item, index) => (
                <div className="col-lg-12 col-md-12 col-sm-1 attend-container">
                  <div className="row">
                    <div style={{ width: "30px" }}>
                      <FaStar color="#F00548" size={"20px"} />
                    </div>
                    <div className="col-lg-11 col-md-11 col-sm-10">
                      <p>
                        <span style={{ color: "black", fontSize: "18px" }}>
                          {item.text1}
                        </span>{" "}
                        <span
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {item.text2}
                        </span>{" "}
                        <span style={{ color: "black", fontSize: "18px" }}>
                          {item.text3}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-center">
              <Button
                variant="contained"
                onClick={() => gotToNewPage()}
                className="yell-btn"
                style={{ color: "white", marginTop: "8%" }}
              >
                Join now for ₹499
              </Button>
            </div>
          </div>
        </div>
      </Container> */}

      {/* What will change your life............................................... */}
      <div className="section-bg py-5" >
        <div className="text-center">
          <h2 className="pt-2" style={{ fontWeight: "bold", color: "#F00548" }}>
            What knowledge you will gain?
          </h2>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-1">
            <img
              // src="https://holistictherapistgayathri.com/wp-content/uploads/2024/01/design-for-Heal-and-Manifest-01.webp"
              src="/assets/changes.png"
              className="img-fluid"
              alt=""
              style={{ padding: "4%" }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-1">
            {tips.map((item, index) => (
              <div key={index} className="col-lg-12 col-md-12 col-sm-1 attend-container">
                <div className="row">
                  <div className="col-lg-1 col-md-1 col-sm-1 col-1 p-0">
                    <FaStar color="#F00548" size={"20px"} />
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-11 col-11 p-0">
                    <p className="mb-0">
                      <span style={{ color: "white", fontSize: "18px" }}>
                        {item.text1}
                      </span>{" "}
                      <span
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      >
                        {item.text2}
                      </span>{" "}
                      <span style={{ color: "white", fontSize: "18px" }}>
                        {item.text3}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* .................what you will learn..................................... */}
      <div className="learn-bg" style={{ padding: "15px" }}>
        <div
          className="text-center title-bg"
          style={{ width: "40%", marginTop: "10px" }}
        >
          <p className="mb-0" style={{ color: "white", fontWeight: "bold" }}>
            What will you Learn in the Webinar
          </p>
        </div>
        <div
          className="row justify-content-space-around pt-3"
        >
          <div className="col-lg-6 col-md-12 col-12">
            <LearningTransparentBox items={learningList[0]} />
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <LearningTransparentBox items={learningList[1]} />
          </div>
        </div>
        <div
          className="row justify-content-space-around"
        >
          <div className="col-lg-6 col-md-12 col-12">
            <LearningTransparentBox items={learningList[2]} />
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <LearningTransparentBox items={learningList[3]} />
          </div>
        </div>
        <div
          className="row justify-content-space-around"
        >
          <div className="col-lg-6 col-md-12 col-12">
            <LearningTransparentBox items={learningList[4]} />
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <LearningTransparentBox items={learningList[5]} />
          </div>
        </div>
        <div className="text-center">
          <Button
            variant="contained"
            onClick={() => gotToNewPage()}
            className="yell-btn"
            style={{ color: "white" }}
          >
            Join NOW AT ₹999
          </Button>
        </div>
      </div>
      {/* This could be your lifee................................................. */}
      <div>
        <div className="text-center mt-5">
          <div className="title-bg">
            <p className="mb-0" style={{ fontWeight: "bold" }}>
              This could be your life
            </p>
          </div>
        </div>

        <div style={{ marginTop: "3%" }}>
          <LeftOverView items={lifeOutput[0]} />
        </div>
        <div>
          <RightOverView items={lifeOutput[1]} />
        </div>

        <div>
          <LeftOverView items={lifeOutput[2]} />
        </div>
        <div>
          <RightOverView items={lifeOutput[3]} />
        </div>

        <div className="text-center">
          <Button
            variant="contained"
            onClick={() => gotToNewPage()}
            className="yell-btn"
            style={{ color: "white" }}
          >
            Join NOW AT ₹999
          </Button>
        </div>
      </div>
      {/* ...............................Bonuses........................................... */}

      <div className="yell-bg py-4" style={{ marginTop: "3%", padding: "10px" }}>
        <div className="text-center title-bg" style={{ width: "20%" }}>
          <p className="mb-0" style={{ color: "white", fontWeight: "800" }}>
            Bonus Tips
          </p>
        </div>
        <div className="row justify-content-center">
          <div
            className="text-center col-lg-6 col-md-6 col-sm-12"
            style={{ marginTop: "3%" }}
          >
            <img
              className="img-fluid"
              src="/assets/bonus-tips.png"
              alt=""
            ></img>
          </div>
        </div>
        <div className="text-center mt-4">
          <Button
            variant="contained"
            onClick={() => gotToNewPage()}
            className="yell-btn"
            style={{ color: "white" }}
          >
            SIGN UP NOW AT ₹999
          </Button>
        </div>
      </div>

      {/* ..............................Success Stories................................. */}
      <div className=" review-bg py-4">
        <div className="text-center title-bg" style={{ width: "30%" }}>
          <p className="mb-0">Success Stories</p>
        </div>

        <div className="row mt-4">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="p-2">
              <SuccessStory story={success_quotes[0]} />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="p-2">
            <SuccessStory story={success_quotes[1]} />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="p-2">
            <SuccessStory story={success_quotes[2]} />
            </div>
          </div>
        </div>

        <div className="text-center">
          <Button
            variant="contained"
            onClick={() => gotToNewPage()}
            className="yell-btn"
            style={{ color: "white" }}
          >
            SIGN UP NOW AT ₹999
          </Button>
        </div>
      </div>
      <div>
        {/*....................................Know your Coach............................................................ */}
        <div className="coach-details-bg">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="coach-img-bg">
                <img
                  src="/assets/revathi-img.png"
                  className="img-fluid"
                  alt=""
                ></img>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-5">
                <h2
                  className="pt-2"
                  style={{
                    color: "white",
                    fontSize: "26px",
                    fontWeight: "800px",
                  }}
                >
                  Know your coach
                </h2>
              </div>
              <div className="mt-1">
                <h1 style={{ color: "#F00548" }}>Revathi Asokan</h1>
              </div>

              <div>
                <p>
                  Introducing Revathi Asokan, a seasoned entrepreneur with a
                  passion for sharing wisdom gained from diverse ventures.
                  Through franchises, retail, e-commerce, and manufacturing,
                  Revathi has weathered the storms of entrepreneurship, emerging
                  stronger and wiser.
                </p>
                <p>
                  Motivated by a desire to empower others, she offers practical
                  guidance and heartfelt encouragement to aspiring business
                  owners. With a focus on overcoming challenges, making informed
                  decisions, and instilling confidence, Revathi is dedicated to
                  equipping participants with the tools they need to thrive in
                  the competitive business landscape.
                </p>

              </div>
              <div className="text-center">
                <Button
                  variant="contained"
                  onClick={() => gotToNewPage()}
                  className="yell-btn"
                  style={{ color: "white" }}
                >
                  Join NOW AT ₹999
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* ...............FAQ........................ */}
        <div>
          <FAQ />
        </div>
        {/* .........................Footer.............. */}
        <Footer />
      </div>
    </section>
  );
}
export default Home;
