import "./App.css";
function LearningTransparentBox(params) {
  return (
    <div>
      <div
        className="trans-bg"
        style={{
          marginTop: "10px",
          display: "flex",
        }}
      >
        <div className="trans-sub-bg">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img src={params.items.img} alt="" height={"60px"}></img>
          </div>
        </div> 
        <h6 style={{ color: "black",fontSize:"18px", paddingTop: "10px", paddingLeft: "10px" ,fontWeight:"600"}}>
          {params.items.subtitle}
        </h6>
      </div>
    </div>
  );
}
export default LearningTransparentBox;
