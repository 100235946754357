import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./home";
import Payment from "./checkout_payment"; 

function App() {
  return (
    <BrowserRouter> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/payment" element={<Payment />} />
      </Routes> 
    </BrowserRouter>
  );       
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

export default App;
